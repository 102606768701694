import React from 'react'
import { Card, CardContent, CardHeader, CardMedia, IconButton, Typography } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Area, Check, Houses, Status } from '../../../../constants/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const publicUrl = process.env.REACT_APP_URL + "/";

export default function ProjectCardForAdmin({ disableLink, hideActions, item, setOpenPopper, setAnchorEl, openPopper, anchorEl, setSelectedProject, selectedProject }) {
    const history = useNavigate();
    const { t } = useTranslation();

    return (
        <Card className='p-0 position-relative radius-16' style={{ overflow: 'hidden' }}>
            <CardHeader
                action={!hideActions ?
                    <IconButton
                        onClick={(e) => {
                            setOpenPopper(!openPopper);
                            setAnchorEl(anchorEl ? null : e.target);
                            setSelectedProject(item);
                        }}
                        sx={{
                            background: '#fff',
                            color: 'var(--main-color-one)',
                            boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
                            '&:hover,&:focus': {
                                color: '#fff',
                                background: 'var(--main-color-one)'
                            }
                        }}>
                        <MoreVertIcon />
                    </IconButton> : null
                }
                sx={{ position: 'absolute', top: 0 }}
            />
            <CardMedia
                onClick={() => disableLink ? setSelectedProject(+item.id) : history(`/admin/project-details/${item.id}`)}
                component="img"
                height="194"
                style={{ cursor: 'pointer' }}
                className={item.images.length > 0 ? "" : "contain-img"}
                image={item.images.length > 0 ? item.images[0] : publicUrl + "assets/img/defimgs/1.svg"}
                alt={item.name}
            />
            <CardContent onClick={() => disableLink ? setSelectedProject(+item.id) : history(`/admin/project-details/${item.id}`)}
                sx={{ minHeight: '200px', maxHeight: '200px', cursor: 'pointer', borderTop: '1px solid #ddd', '&:last-child': { paddingBottom: 0 } }}>
                <Typography variant="h6" color="text.primary" className='admin-card-title'>
                    {item.name}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                    {item.address}
                </Typography>
                <ul className='info-list'>
                    <li> <Status /><span>{t("Status")}: {item.status ? item.status : t("Nothing")}</span></li>
                    <li> <Area /><span>{t("Area")}: {item.area ? Intl.NumberFormat("en").format(item.area) : 0} {t("M2")}</span></li>
                    <li> <Houses /><span>{t("TotalUnits")}: {item.totalUnits}</span></li>
                </ul>
            </CardContent>
            {+item.id === selectedProject && (
                <div className="selected-property-card">
                    <Check />
                </div>
            )}
        </Card>
    );
}
