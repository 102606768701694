import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getLocalizedText, resolvePersianAndArabicNumbers } from "../../helpers";
import LoadingButton from "./loading-btn";

const TranslateText = ({ text, setText, label, textArea, maxLength }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const { t, i18n } = useTranslation();
    const [translatedText, setTranslatedText] = useState("");

    // const translateText = async (text) => {
    //     const apiKey = "AIzaSyBHEdJYUhC4TUpnv2J1dnJ_2S7WBV2uIY0";
    //     const url = `https://translation.googleapis.com/language/translate/v2`;

    //     try {
    //         const response = await fetch(url, {
    //             method: 'POST',
    //             body: JSON.stringify({
    //                 q: text,
    //                 target: "en",
    //                 source: "ar",
    //                 key: apiKey,
    //             })
    //         });
        
    //         setTranslatedText(response.data.data.translations[0].translatedText);
    //     } catch (error) {
    //         console.error("Translation error:", error);
    //     }
    // };

    useEffect(() => {
        if (openDialog && text) {
            if (i18n.language === "ar" && getLocalizedText(text, "en") === "" && getLocalizedText(text, "ar") !== "") {
                // translateText(getLocalizedText(text, "ar"))
            } else {
                setTranslatedText(getLocalizedText(text, i18n.language === "ar" ? "en" : "ar"));
            }
        }
    },// eslint-disable-next-line
        [openDialog, i18n.language]);

    return (
        <>
            {i18n.language === "ar" ?
                <svg
                    style={{ width: 32, height: 32, cursor: 'pointer' }}
                    onClick={() => setOpenDialog(true)}
                    height="512"
                    viewBox="0 0 152 152"
                    width="512"
                    xmlns="http://www.w3.org/2000/svg">
                    <linearGradient id="linear-gradient" gradientUnits="userSpaceOnUse" x1="59.29" x2="148.49" y1="59.29" y2="148.49"><stop offset="0" stop-color="#186eb3" />
                        <stop offset="1" stopColor="#186eb3" stopOpacity="0" />
                    </linearGradient>
                    <g id="Layer_2">
                        <g id="Color">
                            <g id="_05.E">
                                <rect id="Background" fill="var(--main-color-one)" height="152" rx="12" width="152" />
                                <path id="Shadow" d="m152 84.39v55.61a12 12 0 0 1 -12 12h-55.61l-36-36h55.11v-14.84h-35.82v-19.16h30.32v-14.24h-30.32v-16.87h35.93v-14.89z" fill="url(#linear-gradient)" />
                                <path id="Icon" d="m98 82h-30.32v19.12h35.82v14.88h-55.11v-80h55.22v14.89h-35.93v16.87h30.32z" fill="#fff" />
                            </g>
                        </g>
                    </g>
                </svg>
                :
                <svg
                    style={{ width: 32, height: 32, cursor: 'pointer' }}
                    onClick={() => setOpenDialog(true)}
                    height="512" viewBox="0 0 512 512" width="512"
                    xmlns="http://www.w3.org/2000/svg">
                    <linearGradient id="linear-gradient" gradientUnits="userSpaceOnUse" x1="43.93" x2="468.07" y1="43.93" y2="468.07">
                        <stop offset="0" stop-color="var(--main-color-one)" />
                        <stop offset="1" stop-color="#177340" />
                    </linearGradient>
                    <linearGradient id="linear-gradient-2" gradientUnits="userSpaceOnUse" x1="193.02" x2="466.79" y1="195.57" y2="469.34">
                        <stop offset="0" stop-opacity=".5" /><stop offset="1" stop-opacity="0" />
                    </linearGradient>
                    <g id="Icon">
                        <g id="_18" data-name="18">
                            <rect fill="url(#linear-gradient)" height="512" rx="150" width="512" />
                            <path d="m320.45 121.38-85.75 32.51-26.12 71.28 23.94 23.94-59.96 127.63 135.26 135.26h54.18c82.84 0 150-67.16 150-150v-49.07z" fill="url(#linear-gradient-2)" />
                            <path d="m357.68 379.3c0 9.91-13.39 17.03-40.16 21.36-16.06 2.62-32.87 3.93-50.42 3.93-75.2 0-112.79-25.98-112.79-77.93 0-23.58 10.2-43.41 30.59-59.47 9.91-7.86 26.43-16.75 49.56-26.66-11.17-5.13-18.74-9.28-22.73-12.48-7.41-6.04-11.11-13.5-11.11-22.39 0-20.74 8.26-41.87 24.78-63.4 17.89-23.24 37.43-34.86 58.62-34.86 27.34 0 46.43 16.69 57.25 50.07.8 2.51 1.2 5.01 1.2 7.52 0 6.84-1.94 10.25-5.81 10.25-4.1 0-12.33-1.85-24.7-5.55-12.36-3.7-22.08-5.55-29.14-5.55-10.03 0-19.03 1.43-27 4.27-9.46 3.42-14.18 7.92-14.18 13.5 0 3.65 5.92 7.29 17.77 10.94 14.01 4.33 30.82 6.49 50.42 6.49 12.19 0 25.46-.97 39.82-2.91 4.44-.57 6.67.23 6.67 2.39 0 1.14-.4 2.91-1.2 5.3l-19.31 57.59c-10.14-2.05-20.62-3.08-31.45-3.08-21.31 0-38.68 3.82-52.12 11.45-16.98 9.57-25.46 24.21-25.46 43.92 0 21.42 7.86 36.34 23.58 44.78 11.39 6.15 28.03 9.23 49.9 9.23 19.25 0 32.58.11 39.99.34 11.62.34 17.43 3.99 17.43 10.94z" fill="#fff" />
                        </g>
                    </g>
                </svg>
            }
    
            <Dialog
                onClose={() => setOpenDialog(false)}
                maxWidth={"sm"}
                className='custom-dialog'
                style={{ zIndex: 9999999 }}
                sx={{
                    "& .MuiPaper-root": {
                        margin: 0,
                        width: "calc(100% - 20px)",
                        zIndex: 9999999,
                    },
                }}
                open={openDialog}>
                <DialogTitle>
                    <div className="dialog-head">
                        <div>
                            <h2>{i18n.language === "ar" ? "ضبط اللغة الإنجليزيّة" : "Set Arabic language"}</h2>
                        </div>
                        <div className="dialog-actions close-only">
                            <IconButton
                                aria-label='close'
                                onClick={() => setOpenDialog(false)}
                                sx={{ color: (theme) => theme.palette.grey[500] }}>
                                <Close />
                            </IconButton>
                        </div>
                    </div>
                   
                </DialogTitle>
                <DialogContent className="pt-3">
                    {textArea ?
                        <>
                            <TextField
                                label={label}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                inputProps={{ maxLength: maxLength || 300 }}
                                multiline
                           
                                sx={{
                                    '& .MuiInputBase-root': { direction: i18n.language === "ar" ? 'ltr!important' : 'rtl!important' },
                                    '& textarea': {
                                        direction: i18n.language === "ar" ? 'ltr!important' : 'rtl!important',
                                        textAlign: i18n.language === "ar" ? 'left!important' : 'right!important',
                                        textAlignLast: i18n.language === "ar" ? 'left!important' : 'right!important'
                                    }
                                }}
                                rows={6}
                                value={translatedText}
                                onChange={(v) => {
                                    setTranslatedText(resolvePersianAndArabicNumbers(v.currentTarget.value?.length >= maxLength ? v.currentTarget.value?.substring(0, maxLength) : v.currentTarget.value));
                                }}
                            />
                            <h6 className='rightalign mt-2 mb-0'>
                                {translatedText
                                    ? parseInt(translatedText.length) + `/${maxLength}`
                                    : `0/${maxLength}`}
                            </h6>
                        </> :
                        <TextField
                            label={label}
                            variant='outlined'
                            fullWidth
                            sx={{
                                '& .MuiInputBase-root': { direction: i18n.language === "ar" ? 'ltr!important' : 'rtl!important' },
                                '& input': {
                                    direction: i18n.language === "ar" ? 'ltr!important' : 'rtl!important',
                                    textAlign: i18n.language === "ar" ? 'left!important' : 'right!important',
                                    textAlignLast: i18n.language === "ar" ? 'left!important' : 'right!important'
                                }
                            }}
                            InputLabelProps={{ shrink: true }}
                            // className='mt-4'
                            value={translatedText}
                            onChange={(v) => {
                                setTranslatedText(resolvePersianAndArabicNumbers(v.currentTarget.value));
                            }}
                        />
                    }
                  
                    <div className="w-100 mt-3 d-flex justify-content-end">

                        <LoadingButton
                            classes="primary-btn px-3"
                            handleClick={() => {
                                let arabicPart = getLocalizedText(text, "ar");
                                let englishPart = getLocalizedText(text, "en");
                                if (i18n.language === "ar")
                                    englishPart = translatedText;
                                else
                                    arabicPart = translatedText;
                                setText(arabicPart + (englishPart?.length > 0 ? ('~@~' + englishPart) : ""));
                                setOpenDialog(false);
                            }}
                            label={t("Save")}
                            loading={false}
                        />
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default TranslateText;