import React, { useState, useEffect } from "react";
import { getWLServices, saveWLService, uploadWlFileBase64 } from "../../api/userApi";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import { ArrowBack, Delete } from "@mui/icons-material";
import LoadingData from "../global-components/loading-data";
import NoData from "../global-components/no-data";
import LoadingButton from "../global-components/loading-btn";
import { useAuth } from "../../helpers/context";
import { getLocalizedText, resolvePersianAndArabicNumbers } from "../../helpers";
import UploadImage from "../global-components/upload-image";
import DragAndDropFileUploader from "../global-components/drag-drop-file-uploader";
import { CLASSIC2_TEMPLATES, CLASSIC_TEMPLATES, DARK_TEMPLATES, DEMO_VENDORS, MODERN2_TEMPLATES, MODERN_TEMPLATES } from "../../constants";
import SwiperCore, { Autoplay, Keyboard,Pagination } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from "react-i18next";
import T04Services from "../pro/template-04/services";
import T05Services from "../pro/template-05/services";
import T06Services from "../pro/template-06/services";
import { InputAdornment } from "@mui/material";
import TranslateText from "../global-components/translate-text";

SwiperCore.use([Autoplay]);
const publicUrl2 = process.env.REACT_APP_URL + "/";

function Dream(props) {
  const { token, vendor, templateId } = useAuth();
  const [Data, setData] = useState(props.data);
  const [Services, setServices] = useState([]);
  const [uploadingImages, setUploadingImages] = useState([false, false, false]);
  const [loadingData, setLoadingData] = useState(true);
  const { t, i18n } = useTranslation();

  const fromWhiteLabel = window.location.href.includes("minasati") || window.location.href.includes("pro");

  useEffect(() => {
    if (vendor) {
      if (DEMO_VENDORS.includes(vendor)) {
        setLoadingData(true);
        const servicesStorage = localStorage.getItem(`services-${vendor}`) ? JSON.parse(localStorage.getItem(`services-${vendor}`)) : undefined;
        if (servicesStorage?.length > 0) {
          setServices(servicesStorage);
          props?.setSortData?.(servicesStorage);
          setLoadingData(false);
        } else {
          getWLServices({ userName: vendor, language: i18n.language === 'ar' ? '0' : '1' }).then((services) => {
            setServices(services.response);
            props?.setSortData?.(services.response);
            localStorage?.setItem(`services-${vendor}`, JSON.stringify(services.response));
            setLoadingData(false);
          });
        }
      } else {
        setLoadingData(true);
        getWLServices({ userName: vendor, language: i18n.language === 'ar' ? '0' : '1' }).then((services) => {
          setServices(services.response);
          props?.setSortData?.(services.response);
          setLoadingData(false);
        });
      }
    }
  },// eslint-disable-next-line
    [vendor, props.sorted]);

  // const [ImageTOView, setImageTOView] = useState(
  //   publicUrl + "assets/img/others/9.png"
  // );

  // function changeImage() {
  //   setImageTOView(publicUrl + "assets/img/others/9.png?" + Math.random());
  // }

  function uploadImage(file, i, id) {
    let reader = new FileReader();
    // Convert the file to base64 text

    reader.readAsDataURL(file[0]);
    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      let fileInfo = {
        pathWithFileName: 'assets/img/others/' + file[0].name,
        base64Data: reader.result,
        typeOfFile: file[0].type,
        userId: token,
        language: i18n.language === 'ar' ? '0' : '1',
      };
      uploadWlFile(fileInfo, i, id);
    };
  }


  function upload(file, type, sectionNum, id) {
    let reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsDataURL(file);
    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      let fileInfo = {
        pathWithFileName: 'assets/img/others/' + file.name,
        base64Data: reader.result,
        typeOfFile: type,
        userId: token,
        language: i18n.language === 'ar' ? '0' : '1',
      };
      uploadWlFile(fileInfo, sectionNum, id);
    };
  }

  function uploadWlFile(fileInfo, sectionNum, id) {
    if (DEMO_VENDORS.includes(vendor)) {
      setServices(
        Services.map((el) =>
          el.id === id ? { ...el, icon: fileInfo.base64Data } : el
        )
      );
      props?.setSortData?.(Services.map((el) =>
        el.id === id ? { ...el, icon: fileInfo.base64Data } : el
      ));
    } else {
      let temp = [false, false, false];
      temp[sectionNum] = true;
      setUploadingImages(temp);
      
      uploadWlFileBase64(fileInfo).then((resp) => {
        if (resp.resCode === 0) {
          // toast.success("تم الرفع بنجاح");
          setServices(
            Services.map((el) =>
              el.id === id ? { ...el, icon: resp.response.url } : el
            )
          );
          props?.setSortData?.(Services.map((el) =>
            el.id === id ? { ...el, icon: resp.response.url } : el
          ));
  
        } else {
          toast.error(resp.resStr);
        }
        temp[sectionNum] = false;
        setUploadingImages(temp);
      });
    }
   
  }

  const deleteService = (id) => {
    let temp = Services.filter(t => t.id !== id);
    setServices(temp);
    props?.setSortData?.(temp);
  };

  const addNewService = () => {
    let service = {
      id: `${Services.length + 1}${parseInt(Math.random() + 1 * 598)}`,
      title: "",
      icon: "",
      description_small: "",
      description_large: "لا يوجد"
    };
    setServices([service, ...Services]);
    props?.setSortData?.([service, ...Services]);

  };

  const saveServices = () => {
    if (DEMO_VENDORS.includes(vendor)) {
      localStorage?.setItem(`services-${vendor}`, JSON.stringify(Services));
    } else {
      if (props.setLoading)
        props.setLoading(true);
      saveWLService({ userId: token, language: i18n.language === 'ar' ? '0' : '1', services: Services })
        .finally(() => {
          if (props.setLoading)
            props.setLoading(false);
        });
    }
  }

  return (
    <div className={`${props.isEdit ? "services-area-admin" : "services-area"} ${[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? 'template-3__services-area' : ''}`}>
      <div className='row mx-0'>
        <div className='col-12 services-wrapper position-relative p-0'>
          {props.isEdit && !props.goNext && (
            <>
              <div>
                <TextField
                  label={t("Tagline")}
                  variant='outlined'
                  className='w-100'
                  inputProps={{ maxLength: 500 }}
                  multiline
                  rows={3}
                  InputLabelProps={{ shrink: true }}
                  value={getLocalizedText(Data.desc, i18n.language)}
                  onChange={(v) => {
                    let arabicPart = getLocalizedText(Data.desc, "ar");
                    let englishPart = getLocalizedText(Data.desc, "en");
                    if (i18n.language === "ar")
                      arabicPart = v.currentTarget.value?.length >= 500 ? v.currentTarget.value?.substring(0, 500) : v.currentTarget.value;
                    else
                      englishPart = v.currentTarget.value?.length >= 500 ? v.currentTarget.value?.substring(0, 500) : v.currentTarget.value;
                    let text = (arabicPart + (englishPart?.length > 0 ? ('~@~' + englishPart) : ""));
                    setData({ ...Data, desc: resolvePersianAndArabicNumbers(text) });
                  }}
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment position={i18n.language === "ar" ? "end" : "start"}
                          sx={{
                            position: 'absolute',
                            bottom: '8px',
                            left: i18n.language === "ar" ? 0 : 'unset',
                            right: i18n.language === "en" ? 0 : 'unset',
                              
                          }}>
                          <TranslateText
                            textArea
                            maxLength={500}
                            label={i18n.language === "ar" ? "سطر الوصف باللغة الإنجليزيّة" : "Tagline in Arabic"}
                            text={Data.desc} setText={(text) => {
                              setData({ ...Data, desc: resolvePersianAndArabicNumbers(text) });
                            }} />
                        </InputAdornment>
                      ),
                    },
                  }}
                />
                <h6 className='rightalign mt-1 mb-2'>
                  {Data.desc
                    ? parseInt(getLocalizedText(Data.desc, i18n.language)?.length) + "/500"
                    : "0/500"}
                </h6>
              </div>
            </>)}
          
          {
            !props.isEdit ? (
              Services?.length > 0 ? CLASSIC_TEMPLATES.includes(+templateId) ?
                <T04Services data={Services} desc={getLocalizedText(Data.desc, i18n.language, false)} /> :
                CLASSIC2_TEMPLATES.includes(+templateId) ?
                  <T05Services data={Services} desc={getLocalizedText(Data.desc, i18n.language, false)} />
                  : MODERN2_TEMPLATES.includes(+templateId) ?
                    <T06Services data={Services} desc={getLocalizedText(Data.desc, i18n.language, false)} />
                    :
                    (
                      <>
                        <div className='col-12 justify-content-center align-items-center flex-column d-flex' style={{ marginBottom: DARK_TEMPLATES.includes(+templateId) ? 0 : '20px' }}>
                          <span className="section-prefix">{t("OurServices")}</span>
                          <h2 className='sec-title'>{t("ServicesWeProvide")} </h2>
                          <p className="sec-desc">{getLocalizedText(Data.desc, i18n.language, false)}</p>
                        </div>
                        <div className="mb-0 mb-lg-auto" style={{ marginBottom: [...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? '80px' : 0 }}>
                          {[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ?
                            <>
                              <Swiper
                                style={{ minHeight: DARK_TEMPLATES.includes(+templateId) ? '400px' : '500px' }}
                                spaceBetween={40}
                                centeredSlides
                                slidesPerView={1}
                                key={i18n.language + 'ii'}
                                autoplay={{
                                  delay: 2000,
                                  pauseOnMouseEnter: true,
                                  disableOnInteraction: true,
                                }}
                         
                                modules={[Keyboard, Pagination]}
                                keyboard={{ enabled: true }}
                                pagination={{ clickable: true, el: '.swiper-pagination1' }}
                                loop={true}
                                speed={3000}
                              >
                                {Services.map((item, i) => (
                                  <SwiperSlide key={i} >
                                    <div className={`row template-3__about-wrapper my-5  position-relative ${DARK_TEMPLATES.includes(+templateId) ? 'col-12 col-lg-9 mx-auto' : 'mx-0'}`}
                                      style={{ width: '98%' }}>
                                      <div className={`${MODERN_TEMPLATES.includes(+templateId) ? 'col-12 col-lg-6 ' : 'col-12'} template-3__about-content p-0`}>
                                        <div className={`process-area`}>
                                          <div className='row m-0'>
                                            {MODERN_TEMPLATES.includes(+templateId) && <img src="/assets/img/shape-4.png" alt="sahpe" className="shape" />}
                                            <div className='col-12 p-0'>
                                              <div className={`col-12 justify-content-center flex-column d-flex`}>
                                                {MODERN_TEMPLATES.includes(+templateId) && <span className="section-prefix" style={{ fontFamily: "Monoton", fontSize: '28px' }}>{i + 1}.</span>}
                                                <h2 className='sec-title text-center text-lg-start'>
                                                  {getLocalizedText(item.title, i18n.language, false)}
                                                </h2>
                                                <p className="sec-desc text-center text-lg-start">{getLocalizedText(item.description_small, i18n.language, false)}</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className={`col-12 ${MODERN_TEMPLATES.includes(+templateId) ? 'col-lg-5' : ''} p-0`}>
                                        <div className="video-bg">
                                          <img src={item.icon} alt={getLocalizedText(item.title, i18n.language, false)} />
                                        </div>
                                      </div>
                                    </div>
                                  </SwiperSlide>
                                ))}
                              </Swiper>
                              <div className='swiper-pagination1'></div>
                            </>
                            : Services.map((item, i) => (<div key={i} className="row col-12 service-wrapper m-0">
                              <div className="col-12 mb-4 mb-lg-0 mx-auto mx-lg-0 col-lg-6 p-0 position-relative image">
                                {item.icon && <img src={item.icon} alt={getLocalizedText(item.title, i18n.language, false)} className="model-img" />}
                              </div>
                              <div className="col-12 p-0 col-lg-6 details">
                                <h4 className='mb-3'>{getLocalizedText(item.title, i18n.language, false)}</h4>
                                <p>{getLocalizedText(item.description_small, i18n.language, false)}</p>
                              </div>
                            </div>))}
                        </div>
                      </>
                    ) : <></>
            ) : (
              <>
                <div className="col-12 p-0" style={{ marginTop: fromWhiteLabel ? '-45px' : '20px' }}>
                  <div className={`custom-card with-switcher ${fromWhiteLabel ? 'hide-info' : ''}`}>
                    <div className="card-header flex-column flex-sm-row">
                      <div>
                        <h6>{t("AddedServices")}</h6>
                      </div>
                      <div className={fromWhiteLabel ? "mx-auto" : ""}>
                        <button className={`outlined-btn ${fromWhiteLabel ? "px-5 py-2" : "px-3"}`} onClick={addNewService}>{t("AddANewService")}</button>
                      </div>
                    </div>
      
                    <div className="card-body">
                
                      <div className='row justify-content-start'>
                        {loadingData ? <LoadingData /> :
                          Services && Services.length > 0 ? Services.map((item, i) => (
                            <div key={i} className={`${[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? 'col-12 col-lg-4' : 'col-12'}`}>
                              <div key={i} className=" d-flex justify-content-center justify-content-sm-end col-12 mt-4 mb-2 p-0" style={{ width: [...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? '90%' : '100%' }}>
                                <button className="outlined-btn px-3" onClick={() => deleteService(item.id)}>
                                  <Delete />{t("Remove")} {getLocalizedText(item.title, i18n.language) && getLocalizedText(item.title, i18n.language)?.length > 50 ? getLocalizedText(item.title, i18n.language)?.substring(0, 50) : getLocalizedText(item.title, i18n.language)}
                                </button>
                              </div>
                              <div key={i + "i"} className={`row col-12 service-wrapper m-0 ${i % 2 === 0 ? "even" : "odd"}`}>
                                <div
                                  className={`position-relative image col-12 mb-4 p-0  ${[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? 'h-200' : 'my-lg-0  col-lg-6'}`}>
                                  {!uploadingImages[i] && !item.icon &&
                                    <DragAndDropFileUploader
                                      singleFile
                                      ImageOnly
                                      id={`service-img-${i + 1}`}
                                      OnOK={(file) => uploadImage(file, i, item.id)} />}
                                  {uploadingImages[i] && (
                                    <img
                                      className="loading-img"
                                      src={publicUrl2 + "assets/img/loding.gif"}
                                      alt='loading-model'></img>
                                  )}
                                  {!uploadingImages[i] && item.icon && (<>
                                    <img src={item.icon} alt="model-img" className="model-img" />
                                    <UploadImage
                                      token={token}
                                      imgUrl={'assets/img/others/'}
                                      imgSource='assets/img/services/'
                                      from="services"
                                      handleSave={(fileInfo) => uploadWlFile(fileInfo, i, item.id)}
                                      handleChange={(e) => upload(e.target.files[0], "jpg", i, item.id)}
                                    />
                                  </>)}
                                </div>
                                <div className={`col-12 p-0  details ${[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? '' : 'col-lg-6'}`}>
                                  <div>
                                    <TextField
                                      label={`${t("TitleOfTheService")} ${i + 1}`}
                                      variant='outlined'
                                      className='w-100'
                                      value={getLocalizedText(item.title, i18n.language)}
                                      InputLabelProps={{ shrink: true }}
                                     
                                      onChange={(v) => {
                                        let arabicPart = getLocalizedText(item.title, "ar");
                                        let englishPart = getLocalizedText(item.title, "en");
                                        if (i18n.language === "ar")
                                          arabicPart = v.currentTarget.value;
                                        else
                                          englishPart = v.currentTarget.value;
                                        let text = (arabicPart + (englishPart?.length > 0 ? ('~@~' + englishPart) : ""));

                                        setServices(
                                          Services.map((el) =>
                                            el.id === item.id
                                              ? { ...el, title: resolvePersianAndArabicNumbers(text) }
                                              : el
                                          )
                                        );
                                        props?.setSortData?.(
                                          Services.map((el) =>
                                            el.id === item.id
                                              ? { ...el, title: resolvePersianAndArabicNumbers(text) }
                                              : el
                                          )
                                        );
                                      }}
                                      slotProps={{
                                        input: {
                                          endAdornment: (
                                            <InputAdornment position={"end"}>
                                              <TranslateText
                                                label={i18n.language === "ar" ? "العنوان باللغة الإنجليزيّة" : "Title in Arabic"}
                                                text={item.title} setText={(text) => {
                                                  setServices(
                                                    Services.map((el) =>
                                                      el.id === item.id
                                                        ? { ...el, title: resolvePersianAndArabicNumbers(text) }
                                                        : el
                                                    )
                                                  );
                                                  props?.setSortData?.(
                                                    Services.map((el) =>
                                                      el.id === item.id
                                                        ? { ...el, title: resolvePersianAndArabicNumbers(text) }
                                                        : el
                                                    )
                                                  );
                                                
                                                }} />
                                            </InputAdornment>
                                          ),
                                        },
                                      }}
                                    />
                                  </div>
                                  <div>
                                    <TextField
                                      label={`${t("DescriptionOfTheService")} ${i + 1}`}
                                      className='mt-3 w-100'
                                      InputLabelProps={{ shrink: true }}
                                      multiline
                                      rows={7}
                                      inputProps={{ maxLength: 500 }}
                                      value={getLocalizedText(item.description_small, i18n.language)}
                                      slotProps={{
                                        input: {
                                          endAdornment: (
                                            <InputAdornment position={i18n.language === "ar" ? "end" : "start"}
                                              sx={{
                                                position: 'absolute',
                                                bottom: '8px',
                                                left: i18n.language === "ar" ? 0 : 'unset',
                                                right: i18n.language === "en" ? 0 : 'unset',
                                                
                                              }}>
                                              <TranslateText
                                                textArea
                                                maxLength={500}
                                                label={i18n.language === "ar" ? "الوصف باللغة الإنجليزيّة" : "Description in Arabic"}
                                                text={item.description_small} setText={(text) => {
                                                  setServices(
                                                    Services.map((el) =>
                                                      el.id === item.id
                                                        ? { ...el, description_small: resolvePersianAndArabicNumbers(text) }
                                                        : el
                                                    )
                                                  );
                                                  props?.setSortData?.(
                                                    Services.map((el) =>
                                                      el.id === item.id
                                                        ? { ...el, description_small: resolvePersianAndArabicNumbers(text) }
                                                        : el
                                                    )
                                                  );
                                                }} />
                                            </InputAdornment>
                                          ),
                                        },
                                      }}
                                      onChange={(v) => {
                                        let arabicPart = getLocalizedText(item.description_small, "ar");
                                        let englishPart = getLocalizedText(item.description_small, "en");
                                        if (i18n.language === "ar")
                                          arabicPart = v.currentTarget.value?.length >= 500 ? v.currentTarget.value?.substring(0, 500) : v.currentTarget.value;
                                        else
                                          englishPart = v.currentTarget.value?.length >= 500 ? v.currentTarget.value?.substring(0, 500) : v.currentTarget.value;
                                        let text = (arabicPart + (englishPart?.length > 0 ? ('~@~' + englishPart) : ""));
                                        setServices(
                                          Services.map((el) =>
                                            el.id === item.id
                                              ? { ...el, description_small: resolvePersianAndArabicNumbers(text) }
                                              : el
                                          )
                                        );
                                        props?.setSortData?.(
                                          Services.map((el) =>
                                            el.id === item.id
                                              ? { ...el, description_small: resolvePersianAndArabicNumbers(text) }
                                              : el
                                          )
                                        );
                                      }}
                                    />
                                    <h6 className='rightalign mt-2 mb-0'>
                                      {item.description_small
                                        ? parseInt(getLocalizedText(item.description_small, i18n.language)?.length) + "/500"
                                        : "0/500"}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )) :
                            <NoData />
                        }
                      </div>
                
                    </div>
                  </div>
                </div>
                {!props.goNext && (
                  <div className="col-12 text-center d-flex">
                    <LoadingButton
                      classes="primary-btn px-3 m-auto"
                      handleClick={(e) => { props.saveDetails({ data: Data }); saveServices(); }}
                      label={t("SaveChanges")}
                      loading={props.loading}
                    />
                  </div>
                )}
                {props.goNext && (
                  <div className='col-12 mt-5 text-center next-back-btns'>
                    {/* <button className="btn outlined-btn " onClick={props.goBack}>
            <ArrowBack style={{transform:'rotate(180deg)'}} /> السابق
                </button> */}
                    <LoadingButton
                      classes="btn primary-btn"
                      handleClick={() => { saveServices(); props.goNext(); }}
                      label={props.loadingButton ? t("Next") : <>{t("Next")}  <ArrowBack /></>}
                      loading={props.loadingButton}
                    />
                  </div>
                )}
              </>
            )}
        </div>
      </div>
    </div>
  );
}

export default Dream;
