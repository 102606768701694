import React from "react";
import { AddADIcon, AddCustomerIcon, AddonsIcon, AgentsIcon, AmakknUsersMessages, BlogIcon, ClientMessagesIcon, ContactInfoIcon, CustomerSupportIcon, CustomersIcon, CustomersListIcon, DashboardIcon, DealsIcon, DomainIcon, InfoIcon, MatchedCustomersIcon, ProfileIcon, ProjectsIcon, PropertiesIcon, ReportsIcon, SectionsIcon, StylesIcon, TasksIcon, TemplatesIcon, WebManagementIcon } from "../../constants/icons";

const data = {
  menus: [
    {
      text: "Dashboard",
      icon: <DashboardIcon />,
      link: "/admin/dashboard",
      index: 0,
      userType: ['1', '2', '3', '4', '6'],
    },
    {
      text: "Notifications",
      icon: <DashboardIcon />,
      link: "/admin/notifications",
      index: 4540,
      hidden: true,
      userType: ['1', '2', '3', '4', '5', '6'],
    },
    {
      text: "Projects",
      icon: <ProjectsIcon />,
      link: "/admin/projects",
      index: 1,
      accountType: ['2'],
      userType: ['1', '2', '3', '4', '6'],
      needUpgrade: ['6', '7'],
      description: "ProjectsDesc"
    },
    {
      text: "ProjectDetails",
      icon: <ProjectsIcon />,
      link: "/admin/project-details",
      index: 10265,
      accountType: ['2'],
      userType: ['1', '2', '3', '4', '6'],
      needUpgrade: ['6', '7'],
      hidden: true
    },
    {
      text: "AddNewProject",
      icon: <ProjectsIcon />,
      link: "/admin/project",
      index: 10261,
      accountType: ['2'],
      userType: ['1', '2', '3', '4', '6'],
      needUpgrade: ['6', '7'],
      hidden: true
    },
    {
      text: "EditProject",
      icon: <ProjectsIcon />,
      link: "/admin/edit-project",
      index: 10266,
      accountType: ['2'],
      userType: ['1', '2', '3', '4', '6'],
      needUpgrade: ['6', '7'],
      hidden: true
    },
    {
      text: "Properties",
      index: 222,
      icon: <PropertiesIcon />,
      base: true,
      userType: ['1', '2', '3', '4', '5', '6'],
      name: 'ads-management',
    },
    {
      text: "RealEstateADs",
      icon: <PropertiesIcon />,
      subMenu: 'ads-management',
      link: "/admin/property/my-listings",
      index: 2,
      userType: ['1', '2', '3', '4', '5', '6'],
      description: "MyRealEstateADsDesc"
    },
    {
      text: "ADDetails",
      icon: <PropertiesIcon />,
      link: "/admin/property-details",
      index: 20001,
      userType: ['1', '2', '3', '4', '5', '6'],
      hidden: true
    },
    {
      text: "AddAD",
      icon: <AddADIcon />,
      link: "/admin/property/add-step3",
      index: 20002,
      hidden: true,
      userType: ['1', '2', '3', '4', '5', '6']
    },
    {
      text: "AddAD",
      icon: <AddADIcon />,
      link: "/admin/property/add",
      index: 20003,
      userType: ['1', '2', '3', '4', '5', '6'],
      subMenu: 'ads-management'
    },
    {
      text: "InterestedCustomers",
      icon: <MatchedCustomersIcon />,
      link: "/admin/interested-customers",
      index: 89710,
      userType: ['1', '2', '3', '4', '5', '6'],
      subMenu: 'ads-management',
      needUpgrade: ['6', '7'],
      description: "CustomersInterestedInMyADsDesc"
    },
    {
      text: "EditAD",
      icon: <PropertiesIcon />,
      link: "/admin/property/edit-property",
      index: 20004,
      userType: ['1', '2', '3', '4', '5', '6'],
      hidden: true
    },
    {
      text: "CustomersManagement",
      index: 999,
      icon: <CustomersIcon />,
      base: true,
      userType: ['1', '2', '3', '4', '5', '6'],
      name: 'customers-management',
      // needUpgrade: ['6', '7']
    },
    {
      text: "CustomersList",
      icon: <CustomersListIcon />,
      link: "/admin/customers",
      index: 8976,
      userType: ['1', '2', '3', '4', '5', '6'],
      subMenu: 'customers-management',
      // needUpgrade: ['6', '7'],
      description: "CustomersListDesc"
    },
    {
      text: "AddCustomer",
      icon: <AddCustomerIcon />,
      link: "/admin/add-customer",
      index: 8977,
      userType: ['1', '2', '3', '4', '5', '6'],
      subMenu: 'customers-management',
      // needUpgrade: ['6', '7']
    },
    {
      text: "EditCustomer",
      icon: <AddCustomerIcon />,
      link: "/admin/edit-customer",
      index: 8972,
      hidden: true,
      userType: ['1', '2', '3', '4', '5', '6'],
      subMenu: 'customers-management',
      // needUpgrade: ['6', '7']
    },
    {
      text: "MatchedCustomers",
      icon: <MatchedCustomersIcon />,
      link: "/admin/matched-customers",
      index: 8978,
      userType: ['1', '2', '3', '4', '5', '6'],
      subMenu: 'customers-management',
      // needUpgrade: ['6', '7'],
      description: "MatchedCustomersDesc"
    },
    {
      text: "Deals",
      icon: <DealsIcon />,
      link: "/admin/deals",
      index: 8979,
      userType: ['1', '2', '3', '4', '5', '6'],
      subMenu: 'customers-management',
      // needUpgrade: ['6', '7'],
      description: "DealsDesc"
    },
    {
      text: "AddNewDeal",
      icon: <DealsIcon />,
      link: "/admin/add-deal",
      index: 89791,
      userType: ['1', '2', '3', '4', '5', '6'],
      subMenu: 'customers-management',
      hidden: true,
      // needUpgrade: ['6', '7']
    },
    {
      text: "EditDeal",
      icon: <DealsIcon />,
      link: "/admin/edit-deal",
      index: 89792,
      userType: ['1', '2', '3', '4', '5', '6'],
      subMenu: 'customers-management',
      // needUpgrade: ['6', '7'],
      hidden: true
    },
    {
      text: "Tasks",
      icon: <TasksIcon />,
      link: "/admin/tasks",
      index: 8590,
      userType: ['1', '2', '3', '4', '5', '6'],
      // needUpgrade: ['6', '7'],
      description: "TasksDesc"
    },
    {
      text: "AddNewTask",
      link: "/admin/add-task",
      index: 85901,
      userType: ['1', '2', '3', '4', '5', '6'],
      hidden: true,
      // needUpgrade: ['6', '7']
    },
    {
      text: "AddNewTask",
      link: "/admin/add-task-step2",
      index: 85903,
      userType: ['1', '2', '3', '4', '5', '6'],
      hidden: true,
      // needUpgrade: ['6', '7']
    },
    {
      text: "EditTask",
      link: "/admin/edit-task",
      index: 85902,
      userType: ['1', '2', '3', '4', '5', '6'],
      // needUpgrade: ['6', '7'],
      hidden: true
    },
    {
      text: "Blog",
      icon: <BlogIcon />,
      link: "/admin/blog",
      index: 8956,
      userType: ['1', '2', '3', '4', '6'],
      needUpgrade: ['6', '7'],
      description: "BlogDesc"
    },
    {
      text: "NewsDetails",
      icon: <BlogIcon />,
      link: "/admin/news-details",
      index: 89563,
      userType: ['1', '2', '3', '4', '6'],
      needUpgrade: ['6', '7'],
      hidden: true
    },
    {
      text: "AddNewNews",
      icon: <BlogIcon />,
      link: "/admin/news",
      index: 89561,
      userType: ['1', '2', '3', '4', '6'],
      needUpgrade: ['6', '7'],
      hidden: true
    },
    {
      text: "EditNews",
      icon: <BlogIcon />,
      link: "/admin/edit-news",
      index: 89562,
      userType: ['1', '2', '3', '4', '6'],
      needUpgrade: ['6', '7'],
      hidden: true
    },
    // {
    //   text: "عقود الإيجار",
    //   icon: <HomeWorkIcon />,
    //   link: "/admin/ejar-requests",
    //   index: 99,
    //   accountType: ['2'],
    //   userType: ['1', '2', '3', '4', '6'],
    //   plans: ['1', '2', '3', '4', '5', '6', '7', '8', '9']
    // },
    { text: "WebsiteManagement", index: 444, icon: <WebManagementIcon />, base: true, userType: ['1', '2', '3', '4', '6'], name: 'website-management' },
    { text: "Styles", icon: <StylesIcon />, link: "/admin/style", index: 4, userType: ['1', '2', '3', '4', '6'], subMenu: 'website-management' },
    {
      text: "Templates",
      icon: <TemplatesIcon />,
      link: "/admin/templates",
      userType: ['1', '2', '3', '4', '6'],
      index: 5,
      subMenu: 'website-management',
      description: "TemplatesDesc"
    },
    { text: "HomePage", icon: <SectionsIcon />, link: "/admin/sections", index: 6, userType: ['1', '2', '3', '4', '6'], subMenu: 'website-management' },
    { text: "AboutUS", icon: <InfoIcon />, link: "/admin/about", index: 8, userType: ['1', '2', '3', '4', '6'], subMenu: 'website-management' },
    {
      text: "CompanyInformation",
      icon: <ContactInfoIcon />,
      link: "/admin/contact-info",
      index: 11,
      userType: ['1', '2', '3', '4', '6'],
      subMenu: 'website-management'
    },
    {
      text: "Domain",
      icon: <DomainIcon />,
      userType: ['1', '2', '3', '4', '6'],
      link: "/admin/domain",
      index: 7,
      subMenu: 'website-management'
    },
    {
      text: "Addons",
      icon: <AddonsIcon />,
      userType: ['1', '2', '3', '4', '6'],
      link: "/admin/addons",
      index: 59,
      needUpgrade: ['6', '7'],
      subMenu: 'website-management'
    },
    {
      text: "Addons",
      icon: <AddonsIcon />,
      userType: ['5'],
      link: "/admin/addons",
      index: 60,
      needUpgrade: ['6', '7']
    },
    {
      text: "MyProfile",
      icon: <ProfileIcon />,
      link: "/admin/profile",
      index: 15,
      userType: ['1', '2', '3', '4', '5', '6'],
    },
    {
      text: "Agents",
      icon: <AgentsIcon />,
      link: "/admin/agents",
      index: 10,
      accountType: ['2'],
      userType: ['1', '2', '3', '4', '6'],
      // needUpgrade: ['6', '7']
    },
    {
      text: 'Messages',
      index: 333,
      icon: <ClientMessagesIcon />,
      base: true,
      userType: ['1', '2', '3', '4', '5', '6'],
      name: 'msgs-management',
    },
    {
      text: "CustomersMessages",
      icon: <ClientMessagesIcon />,
      link: "/admin/clients-messages",
      index: 9,
      subMenu: 'msgs-management',
      showBadge: true,
      description: "CustomersMessagesDesc",
      userType: ['1', '2', '3', '4', '6']
    },
    {
      text: "AmakknUsersMessages",
      icon: <AmakknUsersMessages />,
      link: "/admin/chat",
      index: 16,
      subMenu: 'msgs-management',
      userType: ['1', '2', '3', '4', '5', '6']
    },
    //  {
    //   text: "Reports",
    //   icon: <AssessmentIcon />,
    //   link: "/admin/reports",
    //   index: 174,
    //   description: "ReportsDesc",
    //   userType: ['1', '2', '3', '4', '6']
    // },
    {
      text: "ADsReports",
      icon: <ReportsIcon />,
      link: "/admin/ads-complaints",
      index: 170,
      description: "ADsReportsDesc",
      userType: ['1', '2', '3', '4', '6']
    },
    {
      text: "CustomerSupport",
      icon: <CustomerSupportIcon />,
      link: "/admin/ticket",
      index: 17,
      userType: ['1', '2', '3', '4', '5', '6']
    },
    // {
    //   text: "ترخيص فال",
    //   icon: <TaskIcon />,
    //   link: "/admin/request-fal",
    //   index: 18,
    //   userType: ['1', '2', '3', '4', '6'],
    //   plans: ['1', '2', '3', '4', '5', '6', '7', '8', '9']
    // }
    {
      text: "Subscription",
      icon: <ProjectsIcon />,
      link: "/admin/plans",
      index: 1451,
      hidden: true,
      userType: ['1', '2', '3', '4', '6']
    },
    {
      text: "AgentProfile",
      icon: <ProjectsIcon />,
      link: "/admin/user-profile",
      index: 1452,
      hidden: true,
      userType: ['1', '2', '3', '4', '6']
    },
    {
      text: "CompanyProfile",
      icon: <ProjectsIcon />,
      link: "/admin/company-profile",
      index: 1453,
      hidden: true,
      userType: ['1', '2', '3', '4', '6']
    },
    {
      text: "ChangePhoneNumber",
      icon: <ProjectsIcon />,
      link: "/admin/change-phone",
      index: 1454,
      hidden: true,
      userType: ['1', '2', '3', '4', '6']
    },
    {
      text: "AddAgent",
      icon: <ProjectsIcon />,
      link: "/admin/add-agent",
      index: 1455,
      hidden: true,
      userType: ['1', '2', '3', '4', '6']
    },
    {
      text: "EditAgent",
      icon: <ProjectsIcon />,
      link: "/admin/edit-agent",
      index: 1456,
      hidden: true,
      userType: ['1', '2', '3', '4', '6']
    },
    {
      text: "ChangePhoneNumber",
      icon: <ProjectsIcon />,
      link: "/admin/change-agent-phone",
      index: 1457,
      hidden: true,
      userType: ['1', '2', '3', '4', '5', '6']
    },
  ]
};

export default data;
